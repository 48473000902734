import React from 'react';
import Header from './Components/Header';
import './index.css';
import MatrixBackground from './Components/MatrixBackround';
import WorkInProgress from './Components/WorkInProgress';
import Section from './Components/Section';

function App() {
  return (
    <div className="App">
      <MatrixBackground /> 
      <Header />
        <WorkInProgress /> 
    </div>
  );
}

export default App;