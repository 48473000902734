import React, { useEffect, useRef } from 'react';

interface MatrixBackgroundProps {
    timeout?: number;
}

const MatrixBackground: React.FC<MatrixBackgroundProps> = ({ timeout = 50 }) => {
    const canvas = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        const context = canvas.current?.getContext('2d');
        
        const resizeCanvas = () => {
            if (canvas.current) {
                canvas.current.width = window.innerWidth;
                canvas.current.height = window.innerHeight;
            }
        };

        resizeCanvas(); 

        window.addEventListener('resize', resizeCanvas); 

        if (!context) return; 

        const columns = Math.floor(window.innerWidth / 20) + 1;
        const yPositions: number[] = Array.from({ length: columns }, () => 0); 

        const matrixEffect = () => {
            if (!context) return;

            context.fillStyle = '#0001';
            context.fillRect(0, 0, window.innerWidth, window.innerHeight); 

            context.fillStyle = '#0f0';
            context.font = '15pt monospace';

            yPositions.forEach((y: number, index: number) => {
                const text = String.fromCharCode(Math.random() * 128);
                const x = index * 20;
                context.fillText(text, x, y);

                if (y > 100 + Math.random() * 10000) {
                    yPositions[index] = 0;
                } else {
                    yPositions[index] = y + 20;
                }
            });
        };

        const interval = setInterval(matrixEffect, timeout);
        return () => {
            clearInterval(interval);
            window.removeEventListener('resize', resizeCanvas); 
        };
    }, [canvas, timeout]);

    return (
        <div
            style={{
                background: 'rgba(26, 26, 26, 0.8)',
                overflow: 'hidden',
                position: 'fixed',
                height: '100%',
                width: '100%',
                zIndex: -1,
                left: '0',
                top: '0',
            }}
        >
            <canvas
                ref={canvas}
            />
        </div>
    );
}

export default MatrixBackground;
