import React from 'react';
import Section from './Section'; // Make sure to import the Section component

const WorkInProgress: React.FC = () => {
  return (
    <Section backgroundColor="bg-custom-gray-opaque" className="m-10">
      <h1 className="text-3xl font-retro text-custom-green text-center"> {/* Custom green color applied */}
        Site is under construction.
      </h1>
    </Section>
  );
};

export default WorkInProgress;