import React, { useEffect, useState } from 'react';

interface SectionProps {
    title?: string;
    children: React.ReactNode;
    backgroundColor?: string;
    className?: string; 
}

const Section: React.FC<SectionProps> = ({
    title,
    children,
    backgroundColor = 'bg-custom-gray-opaque',
    className = '',
}) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setLoaded(true), 100);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div
            className={`${backgroundColor} p-3 md:p-10 m-2 md:m-8 rounded-lg transition-opacity duration-1000 ${loaded ? 'opacity-100' : 'opacity-0'} ${className}`}
        >
            {title && (
                <h2 className="text-2xl font-bold mb-4 text-custom-green font-retro">{title}</h2>
            )}
            {children}
        </div>
    );
}

export default Section;
