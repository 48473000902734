import React from 'react';

const TypingText: React.FC<{ text: string }> = ({ text }) => {
  const [displayedText, setDisplayedText] = React.useState('');
  const [cursorVisible, setCursorVisible] = React.useState(true);
  const indexRef = React.useRef(0);

  React.useEffect(() => {
    const typingInterval = setInterval(() => {
      if (indexRef.current < text.length) {
        setDisplayedText((prev) => prev + text.charAt(indexRef.current));
        indexRef.current++;
      } else {
        clearInterval(typingInterval);
      }
    }, 200); // Typing speed

    const cursorInterval = setInterval(() => {
      setCursorVisible((prev) => !prev);
    }, 500); // Cursor blink speed

    return () => {
      clearInterval(typingInterval);
      clearInterval(cursorInterval);
    };
  }, [text]);

  return (
    <h1 className="text-3xl font-retro" style={{ width: '210px' }}> 
      {displayedText}
      {cursorVisible && (
        <span className="inline-block w-2 h-5 bg-black animate-blink ml-1">
          |
        </span>
      )}
    </h1>
  );
};

const Header: React.FC = () => {
  return (
    <header className="bg-custom-gray text-custom-green p-5 flex flex-col md:flex-row justify-between items-center sticky top-0 z-50 shadow-md">
      <div className="flex-grow flex ml-5 mr-5 md:justify-start justify-center"> 
        <TypingText text="M arco Serfontein" />
      </div>
      <nav className="mt-2 md:mt-0">
        <ul className="list-none p-0 flex space-x-4 text-2xl ml-5 mr-5">
          <li className="font-retro"><a className="text-custom-green hover:underline" href="#about">About</a></li>
          <li className="font-retro"><a className="text-custom-green hover:underline" href="#projects">Projects</a></li>
          <li className="font-retro"><a className="text-custom-green hover:underline" href="#contact">Contact</a></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
